import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from "react";
import Loading from "../../elements/common/Loading";
import ContactClientSearch from "../../elements/contact/ContactClientSearch";
import ContactSearch from "../../elements/contact/ContactSearch";
import MessageDialogSearch from "../../elements/message/MessageDialogSearch";
import PlatformRequest from "../../repository/PlatformRequest";
import compare from '../common/function-compare';
import MultipleSelect from "./MultipleSelect";
import InputMask from 'react-input-mask';


class SearchForm extends Component {

    constructor() {
        super();

        this.state = {
            requiredBox: "light-box",
            loading: false,
            open: false,
            clientContact: false,
            sendSearchMessage: false,
            errorSendSearchMessage: false,
            brandList: [],
            segmentList: [],
            optional: {
                model: "",
                fabYear: 0,
                modelYear: 0
            },
            required: {
                description: false,
                fabYear: false,
                modelYear: false,
                segmentList: false,
                brandCarList: false,
                carTypeList: false,
                nationalityList: false,
                partList: false,
            },
            request: {
                description: "",
                licensePlate: "",
                segmentList: [],
                brandCarList: [],
                carTypeList: [],
                nationalityList: [],
                partList: []
            }
        };
    };



    componentDidMount() {
        PlatformRequest.getBrandList().then((response) => {
            if (response !== null && response.status === 200) {
                this.setState({ ...this.state, brandList: response.body.sort(compare) });
            }
        });

        PlatformRequest.getSegmentList().then((response) => {
            if (response !== null && response.status === 200) {
                this.setState({ ...this.state, segmentList: response.body });
            }
        });
    }

    render() {

        const generateYearList = () => {
            const currentDate = new Date()
            let currentYear = currentDate.getFullYear()

            let years = [currentYear]
            while (currentYear !== 1970) {
                currentYear -= 1
                years.push(currentYear)
            }

            return years;
        }

        const nationalityList = [
            { id: "Nacional", description: "Nacional" },
            { id: "Importado", description: "Importado" },
        ];

        const carTypeList = [
            { id: "Carro", description: "Carro" },
            { id: "Caminhão", description: "Caminhão" },
            { id: "Moto", description: "Moto" },
            { id: "Utilitários", description: "Vans, Vucs, Pick-Ups e Utilitários" },
            { id: "Ônibus", description: "Ônibus" },
            { id: "Tratores", description: "Tratores" },
        ];

        const carPartList = [
            { id: "Nova Original", description: "Nova Original" },
            { id: "Nova Paralela", description: "Nova Paralela" },
            { id: "Usada Original", description: "Usada Original" },
        ];

        const changeDescription = (event) => {
            const request = this.state.request;
            request.description = event.target.value;
            this.setState({ ...this.state, request: request });
        }

        const changeLicensePlate = (event) => {
            const request = this.state.request;
            request.licensePlate = event.target.value.toUpperCase();
            this.setState({ ...this.state, request: request });
        }

        const changeModel = (event) => {
            const optional = this.state.optional;
            optional.model = event.target.value;
            this.setState({ ...this.state, optional: optional });
        }

        const changeFabYear = (event) => {
            const optional = this.state.optional;
            optional.fabYear = event.target.value;
            this.setState({ ...this.state, optional: optional });
        }

        const changeModYear = (event) => {
            const optional = this.state.optional;
            optional.modelYear = event.target.value;
            this.setState({ ...this.state, optional: optional });
        }

        const changeCarType = (event) => {
            const request = this.state.request;
            request.carTypeList = [event.target.value];
            this.setState({ ...this.state, request: request });
        }

        const changeBrandCar = (event) => {
            const request = this.state.request;
            request.brandCarList = [event.target.value];
            this.setState({ ...this.state, request: request });
        }

        const changeSegment = (value) => {
            const request = this.state.request;
            request.segmentList.push(value);

            this.setState({ ...this.state, request: request });
        }

        const changeNationality = (value) => {
            const request = this.state.request;

            if (request.nationalityList.indexOf(value) > -1) {
                return;
            }

            request.nationalityList.push(value);
            this.setState({ ...this.state, request: request });
        }

        const changePart = (value) => {
            const request = this.state.request;
            request.partList.push(value);

            this.setState({ ...this.state, request: request });
        }

        const handleClose = () => {
            this.setState({ ...this.state, open: false })
        };

        const sendSearchMessageClose = () => {
            this.setState({ ...this.state, sendSearchMessage: false, errorSendSearchMessage: false });
            localStorage.removeItem("search");
        };

        const removeSegment = (id) => {
            const segments = this.state.request.segmentList.filter(obj => obj !== id);
            const request = this.state.request;
            request.segmentList = segments;
            this.setState({ ...this.state, request: request });
        }

        const removeCarPart = (id) => {
            const partList = this.state.request.partList.filter(obj => obj !== id);
            const request = this.state.request;
            request.partList = partList;
            this.setState({ ...this.state, request: request });
        }

        const removeNationality = (value) => {
            const nationalityList = this.state.request.nationalityList.filter(obj => obj !== value);
            const request = this.state.request;
            request.nationalityList = nationalityList;
            this.setState({ ...this.state, request: request });
        }

        const validField = (field, state) => {
            if (state[field] === "" || state[field] === undefined || state[field].length === 0 || state[field] === 0) {
                const required = { ...this.state.required, [field]: true }
                this.setState({ ...this.state, required: required, requiredBox: 'wrong-box' })

                return false;
            }

            return true;
        }

        const validatedFields = () => {
            const stateRequest = this.state.request
            const stateOptional = this.state.optional

            if (validField('description', stateRequest) &&
                validField('fabYear', stateOptional) &&
                validField('modelYear', stateOptional) &&
                validField('carTypeList', stateRequest) &&
                validField('brandCarList', stateRequest) &&
                validField('nationalityList', stateRequest) &&
                validField('segmentList', stateRequest) &&
                validField('partList', stateRequest)) {

                return true;
            }
            return false;
        }

        const buildDescription = () => {
            const { request, optional } = this.state
            let description = request.description

            if (optional.model !== "") {
                description += ` ${optional.model}`
            }

            if (optional.fabYear !== 0) {
                description += ` ${optional.fabYear}`
            }

            if (optional.modelYear !== 0) {
                description += `/${optional.modelYear}`
            }

            return description
        }


        const sendWays = () => {
            const logged = JSON.parse(localStorage.getItem('logged'));

            if (validatedFields()) {
                this.setState({ ...this.state, loading: true });
                if (logged !== null && logged === true) {
                    return this.setState({ ...this.state, clientContact: true });
                } else {
                    return this.setState({ ...this.state, open: true });
                }
            }

        }

        const sendSearch = (request) => {
            const defaultDescription = request.description
            request.description = buildDescription();
            localStorage.setItem('search', JSON.stringify(request))

            PlatformRequest.postSearch(request)
                .then((response) => {
                    if (response === 401 || response === 500) {
                        return this.setState({ ...this.state, open: true });
                    }

                    if (response.status === 200) {
                        return this.setState({ ...this.state, loading: false, sendSearchMessage: true });
                    }
                    this.setState({ ...this.state, loading: false, errorSendSearchMessage: true });
                })
                .catch((err) => {
                    this.setState({ ...this.state, loading: false, errorSendSearchMessage: true });
                });

            request.description = defaultDescription;
        }

        const listBrands = this.state.brandList.map((brand, index) => (
            <option value={brand.id} id={index} key={index}>{brand.description}</option>
        ));

        const listType = carTypeList.map((type, index) => (
            <option value={type.id} id={index} key={index}>{type.description}</option>
        ));

        const listYear = generateYearList().map((year, index) => (
            <option value={year} id={index} key={index}>{year}</option>
        ));


        return (
            <div className="slider-activation">
                <Loading open={this.state.loading} />
                {/* Start Single Slide */}
                <div className="slide slide-style-1 slider-fixed--height d-flex align-items-center bg_image bg_image--1" data-black-overlay="6">
                    <div className="container position-relative">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-10 col-12 right-space">
                                            <label htmlFor="search" className="label-input">
                                                <input
                                                    className={this.state.required.description ? "input-text input-required" : "input-text"}
                                                    type="text"
                                                    name="name"
                                                    onChange={changeDescription}
                                                    value={this.state.request.description}
                                                    id="search"
                                                    placeholder="Informe aqui a descrição da peça que deseja pesquisar *"
                                                />
                                            </label>
                                        </div>
                                        <div className="col-lg-3 col-md-3 col-12 right-space">
                                            <label htmlFor="search" className="label-input">
                                            <InputMask
                                                mask="*** ****"  // Máscara para 3 caracteres, hífen e 4 caracteres
                                                maskChar=" "     // Espaço como caractere placeholder
                                                formatChars={{
                                                    '*': '[A-Za-z0-9]'  // Permite letras minúsculas, maiúsculas e números
                                                }}
                                                value={this.state.request.licensePlate}
                                                onChange={changeLicensePlate}
                                            >
                                                {() => (
                                                    <input
                                                        className="input-text"
                                                        type="text"
                                                        name="licensePlate"
                                                        id="search"
                                                        placeholder="Placa do Veículo"
                                                    />
                                                )}
                                            </InputMask>
                                            </label>
                                        </div>
                                        <div className="col-lg-3 col-md-12 col-12 right-space">
                                            <label htmlFor="search" className="label-input">
                                                <input
                                                    className="input-text"
                                                    type="text"
                                                    name="name"
                                                    onChange={changeModel}
                                                    value={this.state.optional.model}
                                                    id="search"
                                                    placeholder="Modelo do Veículo"
                                                />
                                            </label>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-12 right-space">
                                            <label htmlFor="brand" className="label-input">
                                                <select
                                                    type="text"
                                                    name="fabYear"
                                                    id="fabYear"
                                                    className={this.state.required.fabYear ? "input-text input-required" : "input-text"}
                                                    onChange={changeFabYear}
                                                    placeholder="Ano de Fabricação"
                                                >
                                                    <option selected="true" disabled>Ano Fab *</option>
                                                    {listYear}
                                                </select>
                                            </label>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-12 right-space">
                                            <label htmlFor="brand" className="label-input">
                                                <select
                                                    type="text"
                                                    name="modelYear"
                                                    id="modelYear"
                                                    className={this.state.required.modelYear ? "input-text input-required" : "input-text"}
                                                    onChange={changeModYear}
                                                    placeholder="Ano Mod *"
                                                >
                                                    <option selected="true" disabled>Ano Mod *</option>
                                                    {listYear}
                                                </select>
                                            </label>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-12 right-space">
                                            <label htmlFor="brand" className="label-input">
                                                <select
                                                    type="text"
                                                    name="type"
                                                    id="type"
                                                    className={this.state.required.carTypeList ? "input-text input-required" : "input-text"}
                                                    onChange={changeCarType}
                                                    placeholder="Tipo *"
                                                >
                                                    <option selected="true" disabled>Tipo *</option>
                                                    {listType}
                                                </select>
                                            </label>
                                        </div>
                                        <div className="col-lg-3 col-md-12 col-12 right-space">
                                            <label htmlFor="brand" className="label-input">
                                                <select
                                                    type="text"
                                                    name="brand"
                                                    id="brand"
                                                    onChange={changeBrandCar}
                                                    className={this.state.required.brandCarList ? "input-text input-required" : "input-text"}
                                                    placeholder="Marca do veículo *"
                                                >
                                                    <option selected="true" disabled>Marca do Veículo *</option>
                                                    {listBrands}
                                                </select>
                                            </label>
                                        </div>
                                        <div className="col-lg-3 col-md-12 col-12 right-space">
                                            <MultipleSelect
                                                list={nationalityList}
                                                placeholder="Selecione a nacionalidade *"
                                                chips={true}
                                                onRemove={removeNationality}
                                                valid={this.state.required.nationalityList}
                                                selectValues={changeNationality} />
                                        </div>
                                        <div className="col-lg-4 col-md-12 col-12 right-space">
                                            <MultipleSelect
                                                list={this.state.segmentList}
                                                placeholder="Selecione os seguimentos *"
                                                chips={true}
                                                onRemove={removeSegment}
                                                valid={this.state.required.segmentList}
                                                selectValues={changeSegment} />
                                        </div>
                                        <div className="col-lg-3 col-md-12 col-12 right-space">
                                            <MultipleSelect
                                                list={carPartList}
                                                placeholder="Nova, Usada *"
                                                chips={true}
                                                onRemove={removeCarPart}
                                                valid={this.state.required.partList}
                                                selectValues={changePart} />
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-12 right-space">
                                            <button
                                                className="input-button"
                                                type="submit"
                                                value="submit"
                                                name="submit"
                                                onClick={sendWays}
                                                id="mc-embedded-subscribe">Enviar</button>
                                        </div>
                                    </div>
                                </div>
                                <div className={this.state.requiredBox}>
                                    <span className='text-white'> verifique os campos com o contorno vermelho</span>
                                </div>
                            </div>
                        </div>

                        <br />
                        {/* Start Service Area */}
                        <div className="service-wrapper service-white">
                            {/* <ServiceOne /> */}
                        </div>
                        {/* End Service Area */}
                    </div>
                </div>
                {/* End Single Slide */}

                <div>
                    <Dialog
                        open={this.state.open}
                        onClose={handleClose}
                    >
                        <DialogContent>
                            <ContactSearch
                                sendSearch={sendSearch}
                                search={this.state.request} />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.clientContact}
                        onClose={handleClose}
                    >
                        <DialogContent>
                            <ContactClientSearch
                                sendSearch={sendSearch}
                                search={this.state.request} />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.sendSearchMessage}
                        onClose={sendSearchMessageClose}
                    >
                        <DialogContent>
                            <MessageDialogSearch
                                type="success"
                                message="Busca Realizada. Agora Deixa Que Eu Procuro!"
                                subtitle="Agora iremos procurar os melhores fornecedores sobre sua pesquisa, fique atento ao contato informado."
                                buttonMessage="Realizar Nova Busca"
                                close={sendSearchMessageClose} />
                        </DialogContent>
                    </Dialog>

                    <Dialog
                        open={this.state.errorSendSearchMessage}
                        onClose={sendSearchMessageClose}
                    >
                        <DialogContent>
                            <MessageDialogSearch
                                type="error"
                                message="Oops, algo aconteceu de errado ao realizar a busca."
                                subtitle="Tente novamente, se o error persistir, entre em contato conosco através do menu 'Contato'"
                                buttonMessage="Tentar Novamente"
                                close={sendSearchMessageClose} />
                        </DialogContent>
                    </Dialog>
                </div>
            </div>
        );
    }
}
export default SearchForm;